
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  setup() {
    return {
      routerPath: useRoute().path,
    };
  },
});
